<template>
  <b-overlay :show="showOverlay ">
    <b-col class="mb-1 bg-white p-0" cols="12">
      <DynamicBreadCrumb :items="pageBreadcrumb"></DynamicBreadCrumb>
    </b-col>
    <validation-observer ref="updateGameService" tag="div">
      <b-card v-if="gameServiceDetail" class="data-edit-wrapper">
        <h2>Game Service Details</h2>
        <b-form class="mt-2">
          <b-row>
            <b-col class="" md="4">
              <b-form-group
                  label="Title"
              >
                <validation-provider
                    #default="{ errors }"
                    rules="required"
                >
                  <b-form-input
                      id="data-edit-seoTitle"
                      v-model="gameServiceDetail.title"
                      :state="errors.length > 0 ? false:null"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col class="" md="4">
              <b-form-group
                  label="Game Service Detail Name"
              >
                <validation-provider
                    #default="{ errors }"
                    rules="required"
                >
                  <b-form-input
                      id="data-edit-seoTitle"
                      v-model="gameServiceDetail.gameServiceDetailName"
                      :state="errors.length > 0 ? false:null"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                  label="Game Service Type"

              >
                <validation-provider
                    #default="{ errors }"
                    rules="required"
                >
                  <v-select
                      v-model="gameServiceDetail.gameServiceType"
                      :class="{'border-danger rounded':errors.length > 0 ? true:null}"
                      :options="gameServiceTypes"
                      :reduce="title => title.id"
                      dir="ltr"
                      label="title"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                  label="Game Service Category"
              >
                <validation-provider
                    #default="{ errors }"
                    rules="required"
                >
                  <v-select
                      v-model="gameServiceDetail.gameServiceCategoryId"
                      :class="{'border-danger rounded':errors.length > 0 ? true:null}"
                      :options="gameServiceCategories"
                      :reduce="title => title.id"
                      dir="ltr"
                      label="title"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">

              <b-form-group
                  label="Full Description"
              >

                <ckEditorMain ref="editorContent" :defaultContent="gameServiceDetail.shortDescription"
                              @getEditorContent="setEditorContent2"
                ></ckEditorMain>

              </b-form-group>

            </b-col>
            <b-col md="12">
              <b-row>
                <b-col cols="9" md="3">
                  <b-form-group
                      label="Offers"
                  >
                    <b-form-input
                        id="data-edit-seoTitle"
                        v-model="offerName"
                    />
                  </b-form-group>
                </b-col>
                <b-col class="d-flex align-items-center " cols="3" md="2">
                  <b-button
                      class=" mt-1"
                      variant="warning"
                      @click="addOffer"
                  >
                    Add
                  </b-button>
                </b-col>
                <b-col v-if=" gameServiceDetail.gameOfferDtos && gameServiceDetail.gameOfferDtos.length>0" cols="12">
                  <div class="d-flex align-items-center ">
                    <b-badge
                        v-for="(item,idx) in gameServiceDetail.gameOfferDtos"
                        class="d-flex align-items-center mx-25"
                        pill
                        variant="primary"
                    >
                      <span>
                      {{ item.name }}
                      </span>
                      <feather-icon class="cursor-pointer" icon="XIcon" @click="deleteGameServiceOffer(item,idx)"
                      ></feather-icon>
                    </b-badge>
                  </div>
                </b-col>
              </b-row>


            </b-col>
            <b-col class="mt-1" cols="12">
              <b-form-group
                  label="Full Description"
              >

                <ckEditorMain ref="editorContent" :defaultContent="gameServiceDetail.fullDescription"
                              @getEditorContent="setEditorContent"
                ></ckEditorMain>

              </b-form-group>

            </b-col>
            <b-col class="my-1" cols="12">
              <b-form-group
                  label="Background Picture"
              >
                <div class="">
                  <div
                      class=" position-relative   border rounded  shadow     "
                      style="height: 10rem;width: 10rem"
                  >
                    <!--          <span-->
                    <!--              class="position-absolute cursor-pointer top-0 left-0 bg-white shadow d-flex align-items-center justify-content-center rounded-circle"-->
                    <!--              style="width: 2rem;height: 2rem"-->
                    <!--          >-->
                    <!--            <feather-icon class="text-danger" icon="TrashIcon"></feather-icon>-->
                    <!--          </span>-->
                    <img :src="gameServiceDetail.backgroundImage" alt=""
                         style="object-fit: contain;height: 10rem;width: 10rem"
                    >
                  </div>

                </div>

              </b-form-group>

            </b-col>
            <b-col class="my-1" cols="12">
              <b-form-group
                  label="New Background Picture"
              >
                <MediaHandler ref="mediaHandlerBackground" @sendData="setFilesBackground"></MediaHandler>

              </b-form-group>

            </b-col>
            <b-col class="my-1" cols="12">
              <b-form-group
                  label="Item Picture"
              >
                <div class="">
                  <div
                      class=" position-relative   border rounded  shadow     "
                      style="height: 10rem;width: 10rem"
                  >
                    <!--          <span-->
                    <!--              class="position-absolute cursor-pointer top-0 left-0 bg-white shadow d-flex align-items-center justify-content-center rounded-circle"-->
                    <!--              style="width: 2rem;height: 2rem"-->
                    <!--          >-->
                    <!--            <feather-icon class="text-danger" icon="TrashIcon"></feather-icon>-->
                    <!--          </span>-->
                    <img :src="gameServiceDetail.itemImage" alt=""
                         style="object-fit: contain;height: 10rem;width: 10rem"
                    >
                  </div>

                </div>

              </b-form-group>

            </b-col>
            <b-col class="my-1" cols="12">
              <b-form-group
                  label=" New Item Picture"
              >
                <MediaHandler ref="mediaHandlerItem" @sendData="setFilesItem"></MediaHandler>

              </b-form-group>

            </b-col>


          </b-row>
        </b-form>
      </b-card>
      <b-modal
          id="modal-delete"
          cancelTitle="Cancel"
          centered
          ok-title="Delete"

          title="Delete Game Service content"
          @ok.prevent="deleteGameServiceContent"
      >
        <div class="row">
          <div class="col-md-12">
            <span>Are you sure you want to delete this game service content ?</span>
          </div>
        </div>
      </b-modal>
      <b-modal
          id="modal-delete-product"
          cancelTitle="Cancel"
          centered
          ok-title="Delete"
          title="Delete Product"
          @ok.prevent="deleteGameServiceProduct"
      >
        <div class="row">
          <div class="col-md-12">
            <span>Are you sure you want to delete this game service product ?</span>
          </div>
        </div>
      </b-modal>

      <b-card v-if="gameServiceDetail">
        <b-row>
          <b-col cols="12" md="12">
            <h2>SEO</h2>
          </b-col>
          <b-col class="" cols="12" md="4">
            <b-form-group
                label="Seo Title"
            >
              <validation-provider
                  #default="{ errors }"
                  rules="required"
              >
                <b-form-input
                    id="data-edit-seoTitle"
                    v-model="gameServiceDetail.seoTitle"
                    :state="errors.length > 0 ? false:null"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col class="" cols="12" md="4">
            <b-form-group
                label="Seo Url"
            >
              <validation-provider
                  #default="{ errors }"
                  rules="required"
              >
                <b-form-input
                    id="data-edit-seoTitle"
                    v-model="allCharactersToDash"
                    :state="errors.length > 0 ? false:null"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col class="" cols="12">
            <b-form-group
                label="Seo Description"
            >
              <validation-provider
                  #default="{ errors }"
                  rules="required"
              >

                <b-form-textarea
                    id="data-edit-seoTitle"
                    v-model="gameServiceDetail.seoDescription"
                    :state="errors.length > 0 ? false:null"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col class="pl-1" cols="12">
            <div class="col-md-12 ">
              <!--- serp Desktop --->
              <div class="serp-preview d-lg-flex d-none flex-column align-items-start">
                <div class="serp-title">
                  <h4 style="color: rgb(26, 13, 171)!important">
                    {{ gameServiceDetail.seoTitle }}
                  </h4>
                </div>
                <div class="serp-url">
                  <h5 class="d-flex align-items-center flex-wrap" style="color: rgb(0, 102, 33)!important">
                    <span>https://skycoach.com/[game-name]/[game-service-category-name]/<span class="serp-arrow"></span></span>
                    <span v-if="gameServiceDetail.seoUrl" style="overflow-wrap: anywhere">
                      {{ gameServiceDetail.seoUrl.replaceAll(' ', '-') }}
                    </span>

                  </h5>
                </div>
                <div class="serp-description">
                  <h6 style="overflow-wrap: anywhere">
                    {{ gameServiceDetail.seoDescription }}
                  </h6>
                </div>
              </div>
              <!--- serp Mobile --->
              <div class="serp-preview d-lg-none  p-1 d-flex flex-column align-items-start">
                <div class="serp-title">
                  <h6>
                    {{ gameServiceDetail.seoTitle }}
                  </h6>
                </div>
                <div class="serp-url d-flex align-items-center flex-wrap">

                  <small>{{ gameServiceDetail.seoUrl.replaceAll(' ', '-') }}/</small>
                  <small>
                    https://skycoach.com/[game-name]/[game-service-category-name]/
                    <small class="serp-arrow"></small>
                  </small>
                </div>
                <div class="serp-description">
                  <small>
                    {{ gameServiceDetail.seoDescription }}
                  </small>
                </div>
              </div>
            </div>
          </b-col>
          <b-col cols="3">
            <b-button
                class="btn-tour-skip mr-1"
                variant="primary"
                @click="updateGameService"
            >
              <span class="mr-25 align-middle">Submit</span>

            </b-button>
          </b-col>
        </b-row>
      </b-card>
      <b-card v-if="gameServiceDetail">
        <b-row>
          <b-col cols="12" md="12">
            <h2>Game Service Contents</h2>
          </b-col>
          <b-col class="my-1" cols="3" md="12">
            <b-button
                class="btn-tour-skip mr-1"
                variant="primary"
            >
              <b-link
                  :to="`/apps/games/game-service-categories/game-service/game-service-content/create-game-service-content?gameId=${$route.query.gameId}&gameServiceCategoryId=${$route.query.gameServiceCategoryId}&gameServiceId=${$route.query.gameServiceId}`"
              >
                <span class="mr-25 align-middle text-white">Create</span>
              </b-link>

            </b-button>
          </b-col>
          <b-col cols="12">
            <b-table
                ref="refUserListTable"
                :fields="myTableColumns2"
                :items="gameServiceDetail.gameServiceContentSectionDtos"
                bordered
                class="position-relative"
                empty-text="Nothing found !"
                primary-key="id"
                responsive
                show-empty
                striped
            >

              <template #cell(imageUrl)="data">
                <img :src="data.item.imageUrl" alt="" style="width: 5rem;height: 5rem;object-fit: contain">
              </template>
              <template #cell(operation)="data">
                <div class="d-flex align-items-center  w-100">
                  <b-link
                      :to="`/apps/games/game-service-categories/game-service/game-service-content/edit-game-service-content?gameId=${$route.query.gameId}&gameServiceCategoryId=${$route.query.gameServiceCategoryId}&gameServiceId=${$route.query.gameServiceId}&gameServiceContentId=${data.item.gameServiceContentSectionId}`"
                  >
                    <feather-icon
                        class="text-primary"
                        icon="EditIcon"
                        size="20"
                    />
                  </b-link>
                  <span v-b-modal.modal-delete class="cursor-pointer mx-1" @click="setSelectedContent(data.item)">
              <feather-icon
                  class="text-danger"
                  icon="TrashIcon"
                  size="20"
              />
              </span>
                </div>
              </template>
            </b-table>

          </b-col>
          <!--          <b-col cols="12">-->
          <!--            <div class="mx-2 mb-2">-->
          <!--              <b-row>-->
          <!--                <b-col-->
          <!--                    class="d-flex align-items-center justify-content-center"-->
          <!--                    cols="12"-->
          <!--                    sm="12"-->
          <!--                >-->
          <!--                  &lt;!&ndash;            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>&ndash;&gt;-->
          <!--                </b-col>-->
          <!--                &lt;!&ndash; Pagination &ndash;&gt;-->
          <!--                <b-col-->
          <!--                    class="d-flex align-items-center justify-content-center justify-content-sm-end"-->
          <!--                    cols="12"-->
          <!--                    sm="6"-->
          <!--                >-->
          <!--                  <b-pagination-->
          <!--                      v-model="currentPage"-->
          <!--                      :per-page="perPage"-->
          <!--                      :total-rows="totalCount"-->
          <!--                      class="mb-0 mt-1 mt-sm-0"-->
          <!--                      first-number-->
          <!--                      last-number-->
          <!--                      next-class="next-item"-->
          <!--                      prev-class="prev-item"-->
          <!--                  >-->
          <!--                    <template #prev-text>-->
          <!--                      <feather-icon-->
          <!--                          icon="ChevronLeftIcon"-->
          <!--                          size="18"-->
          <!--                      />-->
          <!--                    </template>-->
          <!--                    <template #next-text>-->
          <!--                      <feather-icon-->
          <!--                          icon="ChevronRightIcon"-->
          <!--                          size="18"-->
          <!--                      />-->
          <!--                    </template>-->
          <!--                  </b-pagination>-->
          <!--                </b-col>-->
          <!--              </b-row>-->
          <!--            </div>-->
          <!--          </b-col>-->
        </b-row>
      </b-card>
      <b-card v-if="gameServiceDetail">
        <b-row>
          <b-col cols="12" md="12">
            <h2>Game Service Products</h2>
          </b-col>
          <b-col v-if="serviceProducts && serviceProducts.length===0" class="my-1" cols="3" md="12">
            <b-button
                class="btn-tour-skip mr-1"
                variant="primary"
            >
              <b-link
                  :to="`/apps/games/game-service-categories/game-service/products/create-product?gameId=${$route.query.gameId}&gameServiceCategoryId=${$route.query.gameServiceCategoryId}&gameServiceId=${$route.query.gameServiceId}`"
              >
                <span class="mr-25 align-middle text-white">Create</span>
              </b-link>

            </b-button>
          </b-col>
          <b-col cols="12">
            <b-table
                ref="refUserListTable"
                :fields="myTableColumns"
                :items="serviceProducts"
                :sort-by.sync="sortBy"
                bordered
                class="position-relative"

                empty-text="Nothing found !"
                primary-key="id"
                responsive
                show-empty
                striped
            >

              <template #cell(imageUrl)="data">
                <img :src="data.item.imageUrl" alt="" style="width: 5rem;height: 5rem;object-fit: contain">
              </template>
              <template #cell(operation)="data">
                <div class="d-flex align-items-center  w-100">
                  <b-link
                      :to="`/apps/games/game-service-categories/game-service/products/manage-product?gameId=${$route.query.gameId}&gameServiceCategoryId=${$route.query.gameServiceCategoryId}&gameServiceId=${$route.query.gameServiceId}&productId=${data.item.productId}`"
                  >
                    <feather-icon
                        class="text-primary"
                        icon="EditIcon"
                        size="20"
                    />
                  </b-link>
                  <span v-b-modal.modal-delete-product class="cursor-pointer mx-1"
                        @click="setSelectedProduct(data.item)"
                  >
              <feather-icon
                  class="text-danger"
                  icon="TrashIcon"
                  size="20"
              />
              </span>
                </div>
              </template>
            </b-table>

          </b-col>
          <!--          <b-col cols="12">-->
          <!--            <div class="mx-2 mb-2">-->
          <!--              <b-row>-->
          <!--                <b-col-->
          <!--                    class="d-flex align-items-center justify-content-center"-->
          <!--                    cols="12"-->
          <!--                    sm="12"-->
          <!--                >-->
          <!--                  &lt;!&ndash;            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>&ndash;&gt;-->
          <!--                </b-col>-->
          <!--                &lt;!&ndash; Pagination &ndash;&gt;-->
          <!--                <b-col-->
          <!--                    class="d-flex align-items-center justify-content-center justify-content-sm-end"-->
          <!--                    cols="12"-->
          <!--                    sm="6"-->
          <!--                >-->
          <!--                  <b-pagination-->
          <!--                      v-model="currentPage"-->
          <!--                      :per-page="perPage"-->
          <!--                      :total-rows="totalCount"-->
          <!--                      class="mb-0 mt-1 mt-sm-0"-->
          <!--                      first-number-->
          <!--                      last-number-->
          <!--                      next-class="next-item"-->
          <!--                      prev-class="prev-item"-->
          <!--                  >-->
          <!--                    <template #prev-text>-->
          <!--                      <feather-icon-->
          <!--                          icon="ChevronLeftIcon"-->
          <!--                          size="18"-->
          <!--                      />-->
          <!--                    </template>-->
          <!--                    <template #next-text>-->
          <!--                      <feather-icon-->
          <!--                          icon="ChevronRightIcon"-->
          <!--                          size="18"-->
          <!--                      />-->
          <!--                    </template>-->
          <!--                  </b-pagination>-->
          <!--                </b-col>-->
          <!--              </b-row>-->
          <!--            </div>-->
          <!--          </b-col>-->
        </b-row>
      </b-card>

    </validation-observer>

  </b-overlay>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import {
  BBadge,
  BButton,
  BCard,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BLink,
  BModal,
  BOverlay,
  BRow,
  BTable
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import ckEditorMain from '@/views/components/ckEditorMain'
import Helper from '@/libs/Helper'
import MediaHandler from '@/views/components/utilities/MediaHandler.vue'
import {
  CreateGameServiceOffer,
  DeleteGameServiceContent,
  DeleteGameServiceOffer,
  GetGameServiceDetail,
  UpdateGameService
} from '@/libs/Api/gameService'
import { DeleteProduct, GetProductsByGameServiceId } from '@/libs/Api/product'
import DynamicBreadCrumb from '@/views/components/utilities/DynamicBreadCrumb.vue'
import { GetGameServiceCategoriesByGameId } from '@/libs/Api/games'

export default {
  components: {
    DynamicBreadCrumb,
    MediaHandler,
    BFormTextarea,
    BForm,
    ValidationProvider,
    ValidationObserver,
    ckEditorMain,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    BTable,
    BLink,
    BBadge,
    BOverlay,
    BModal,
    vSelect,
  },
  name: 'manage-game-services',
  title: 'manage game services',
  data() {
    return {
      required,
      showOverlay: false,
      baseUrl: Helper.baseUrl,
      totalCount: null,
      perPage: 10,
      selectedProduct: null,
      sortBy: '',
      currentPage: 1,
      serviceProducts: null,
      offerName: '',
      selectedContent: null,
      gameServiceTypes: [
        {
          title: 'service',
          id: 1
        },
        {
          title: 'gold',
          id: 2
        }
      ],
      gameServiceCategories: [],
      gameServiceDetail: null,
      myTableColumns2: [
        {
          key: 'gameServiceContentSectionId',
          label: 'id',
          sortable: true
        },
        {
          key: 'title',
          label: 'title',
          sortable: true
        },

        {
          key: 'operation',
          label: 'operation'
        },
      ],
      myTableColumns: [
        {
          key: 'productId',
          label: 'id',
          sortable: true
        },
        {
          key: 'name',
          label: 'name',
          sortable: true
        },

        {
          key: 'operation',
          label: 'operation'
        },
      ],
      pageBreadcrumb: [
        {
          text: 'Manage Games',
          href: '/apps/games/manage-games',
          active: false
        },
        {
          text: `Manage Game`,
          href: `/apps/games/manage-game?gameId=${this.$route.query.gameId}`,
          active: false
        },
        {
          text: `Manage Game Service Category`,
          href: `/apps/games/game-service-categories/manage-game-service-category?gameId=${this.$route.query.gameId}&gameServiceCategoryId=${this.$route.query.gameServiceCategoryId}`,
          active: false
        },
        {
          text: `Manage Game Service`,
          href: `/apps/games/game-service-categories/game-service/manage-game-services?gameId=${this.$route.query.gameId}&gameServiceCategoryId=${this.$route.query.gameServiceCategoryId}&gameServiceId=${this.$route.query.gameServiceId}`,
          active: true
        },
      ],

    }
  },
  computed: {
    allCharactersToDash: {
      get: function () {
        return this.gameServiceDetail.seoUrl
      },
      set: function (newValue) {
        // This setter is getting number, replace all commas with empty str
        // Then start to separate numbers with ',' from beginning to prevent
        // from data corruption
        if (newValue) {
          this.gameServiceDetail.seoUrl = newValue
          // Remove all characters that are NOT number
          this.gameServiceDetail.seoUrl = this.gameServiceDetail.seoUrl.replace(/[+*!@#$%^&*()_;:~`»«,×='"|<>/?{}\-/\.]/g, '-')
          this.gameServiceDetail.seoUrl = this.gameServiceDetail?.seoUrl?.replaceAll(' ', '-')
        } else if (!newValue || this.gameServiceDetail.seoUrl === '') {
          this.gameServiceDetail.seoUrl = null
        }
      },
    },
  },
  async created() {
    await Promise.all([
      this.getGameServiceDetail(),
      this.getProductsForGameService(),
      this.getGameServiceCategoriesByGameId()
    ])
  },
  watch: {
    currentPage: function (val) {
      this.getProductsForGameService()
    }
  },
  methods: {
    async getGameServiceCategoriesByGameId() {
      let _this = this
      let getGameServiceCategoriesByGameId = new GetGameServiceCategoriesByGameId(_this)
      let data = {
        PageNumber: 1,
        count: 600,
      }
      getGameServiceCategoriesByGameId.setPartialUrl({ id: _this.$route.query.gameId })
      getGameServiceCategoriesByGameId.setParams(data)
      await getGameServiceCategoriesByGameId.fetch(function (content) {
        _this.gameServiceCategories = content.data.data
        console.log(_this.gameServiceCategories)

      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })
    },
    async deleteGameServiceProduct() {
      let _this = this
      _this.showOverlay = true
      let deleteProduct = new DeleteProduct(_this)
      deleteProduct.setParams({ productId: _this.selectedProduct.productId })
      await deleteProduct.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          props: {
            title: `success`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `successfully deleted`,
          },
        })
        _this.getProductsForGameService()
        _this.$nextTick(() => {
          _this.$bvModal.hide('modal-delete-product')
        })
        _this.showOverlay = false
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })
    },
    setSelectedProduct(item) {
      this.selectedProduct = JSON.parse(JSON.stringify(item))
    },
    async getProductsForGameService() {
      let _this = this
      _this.showOverlay = true

      let getProductsByGameServiceId = new GetProductsByGameServiceId(_this)
      let data = {
        gameServiceId: _this.$route.query.gameServiceId,
        pageNumber: _this.currentPage,
        count: _this.perPage
      }
      getProductsByGameServiceId.setParams(data)
      await getProductsByGameServiceId.fetch(function (content) {
        _this.showOverlay = false
        _this.serviceProducts = content.data.data
        _this.totalCount = content.data.totalCount
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })
    },
    setSelectedContent(item) {
      this.selectedContent = JSON.parse(JSON.stringify(item))
    },
    async updateGameService() {
      let _this = this
      _this.showOverlay = true
      let updateGameService = new UpdateGameService(_this)
      updateGameService.setParams({
        gameServiceId: _this.$route.query.gameServiceId,
        gameServiceCategoryId: _this.gameServiceDetail.gameServiceCategoryId
      })
      _this.$refs.mediaHandlerBackground.sendFiles()
      _this.$refs.mediaHandlerItem.sendFiles()
      let myForm = await Helper.makeFormData(this.gameServiceDetail)
      updateGameService.setForm(myForm)
      await updateGameService.sendFormData(function (content) {
        _this.showOverlay = false
        if (content.isSuccess) {
          _this.offerName = ''
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              title: `Done`,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          _this.$refs.mediaHandlerBackground.clearMedia()
          _this.$refs.mediaHandlerItem.clearMedia()
          _this.getGameServiceDetail()
        } else {
          return _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              text: content.message,
              title: `error`,
              variant: 'danger',
            },
          })
        }
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })

    },
    async addOffer() {
      let _this = this
      if (_this.offerName) {

        _this.showOverlay = true
        let createGameServiceOffer = new CreateGameServiceOffer(_this)
        createGameServiceOffer.setParams({
          offer: _this.offerName,
          gameServiceId: _this.$route.query.gameServiceId
        })
        await createGameServiceOffer.fetch(function (content) {
          _this.showOverlay = false
          if (content.isSuccess) {
            _this.offerName = ''
            _this.$toast({
              component: ToastificationContent,
              position: 'bottom-center',
              props: {
                title: `Done`,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            _this.getGameServiceDetail()
          } else {
            return _this.$toast({
              component: ToastificationContent,
              position: 'bottom-center',
              props: {
                text: content.message,
                title: `error`,
                variant: 'danger',
              },
            })
          }
        }, function (error) {
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              text: error.message,
              title: `error`,
              variant: 'danger',
            },
          })
          _this.showOverlay = false
        })
      } else {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: 'please fill in the offer name',
            title: `error`,
            variant: 'danger',
          },
        })
      }
    },
    async deleteGameServiceOffer(item, idx) {
      let _this = this
      _this.showOverlay = true
      let deleteGameServiceOffer = new DeleteGameServiceOffer(_this)
      deleteGameServiceOffer.setParams({ gameServiceOfferId: item.offerId })
      await deleteGameServiceOffer.fetch(function (content) {
        _this.showOverlay = false
        if (content.isSuccess) {
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              title: `Done`,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          _this.gameServiceDetail.gameOfferDtos.splice(idx, 1)
        } else {
          return _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              text: content.message,
              title: `error`,
              variant: 'danger',
            },
          })
        }
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })
    },
    async deleteGameServiceContent() {
      let _this = this
      _this.showOverlay = true
      let deleteGameServiceContent = new DeleteGameServiceContent(_this)
      deleteGameServiceContent.setParams({ gameServiceContentSectionId: _this.selectedContent.gameServiceContentSectionId })
      await deleteGameServiceContent.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          props: {
            title: `success`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `successfully deleted`,
          },
        })
        _this.getGameServiceDetail()
        _this.$bvModal.hide('modal-delete')
        _this.showOverlay = false
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })
    },
    async setFilesItem(data) {
      if (data) {
        this.gameServiceDetail['ItemImage'] = data
      }
    },
    async setFilesBackground(data) {
      if (data) {
        this.gameServiceDetail['BackgroundImage'] = data
      }
    },
    setEditorContent(content) {
      this.gameServiceDetail.fullDescription = content
    },
    setEditorContent2(content) {
      this.gameServiceDetail.shortDescription = content
    },
    async getGameServiceDetail() {
      let _this = this
      _this.showOverlay = true

      let getGameServiceDetail = new GetGameServiceDetail(_this)
      let data = {
        id: _this.$route.query.gameServiceId
      }
      getGameServiceDetail.setPartial(data)
      await getGameServiceDetail.fetch(function (content) {
        _this.showOverlay = false
        _this.gameServiceDetail = content.data
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })
    },

  }
}
</script>

<style scoped>

</style>
